import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import PropTypes, { InferProps } from 'prop-types';
import { PaginationModel } from '../model/PaginationModel';
function Pagination({ pagination, onChange }: InferProps<typeof Pagination.propTypes>) {
  return (
    <div>
      {(pagination?.totalPages || 0) > 1 && (
        <div className="flex items-center justify-between py-3">
          <div className="flex flex-1 justify-between sm:hidden">
            <button
              onClick={() => onChange((pagination?.page || 0) - 1)}
              disabled={!pagination?.hasPrevPage}
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
              Previous
            </button>
            <button
              onClick={() => onChange((pagination?.page || 0) + 1)}
              disabled={!pagination?.hasNextPage}
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium disabled:text-gray-700 text-indigo-500 hover:bg-gray-50">
              Next
            </button>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-[#94A3B8]">
                Showing
                <span className="font-medium mx-1 text-[#1E293B]">
                  {((pagination?.page || 0) - 1) * (pagination?.limit || 0) + 1}
                </span>
                to
                <span className="font- mx-1 text-[#1E293B]">
                  {(pagination?.page || 0) * (pagination?.limit || 0)}
                </span>
                of
                <span className="font-medium mx-1 text-[#1E293B]">{pagination?.totalDocs}</span>
                results
              </p>
            </div>
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination">
                <button
                  disabled={!pagination?.hasPrevPage}
                  onClick={() => onChange((pagination?.page || 0) - 1)}
                  className="relative inline-flex disabled:cursor-not-allowed items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium disabled:text-gray-500 disabled:hover:text-gray-500 disabled:hover:bg-white text-indigo-500 hover:bg-indigo-500 hover:text-white focus:z-20">
                  <span className="sr-only">Previous</span>
                  <ChevronLeftIcon className="h-5 w-5"></ChevronLeftIcon>
                </button>
                {Array.from(Array(pagination?.totalPages).keys()).map((item) => {
                  return (
                    <button
                      onClick={() => onChange(item + 1)}
                      key={item}
                      aria-current="page"
                      className={`relative z-10 inline-flex items-center border  px-4 py-2 text-sm font-medium ${
                        item + 1 === pagination?.page
                          ? ' text-indigo-600 focus:z-20 border-indigo-500 bg-indigo-50 '
                          : ' border-gray-300 bg-white text-gray-500 hover:bg-gray-50 '
                      } `}>
                      {item + 1}
                    </button>
                  );
                })}
                <button
                  disabled={!pagination?.hasNextPage}
                  onClick={() => onChange((pagination?.page || 0) + 1)}
                  className="relative inline-flex disabled:cursor-not-allowed items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium disabled:text-gray-500 disabled:hover:bg-white disabled:hover:text-gray-500 text-indigo-500 hover:bg-indigo-500 hover:text-white focus:z-20">
                  <span className="sr-only">Next</span>
                  <ChevronRightIcon className="h-5 w-5"></ChevronRightIcon>
                </button>
              </nav>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

Pagination.propTypes = {
  pagination: PropTypes.instanceOf(PaginationModel),
  onChange: () => void {}
};

export default Pagination;
