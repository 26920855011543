import React, { useState } from 'react';
import DashboardHeader from './header';
import DashboardSidebar from './DashboardSidebar';

function DashboardLayout({ children }: any) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-hidden bg-[#F1F5F9]">
      {/* Content area */}
      <div
        dir="rtl"
        className="relative flex flex-col flex-1 overflow-y-auto  scrollbar-thin scrollbar-thumb-[#AAAAAA] scrollbar-track-[#F0F0F0] overflow-x-hidden">
        {/*  Site header */}
        <DashboardHeader sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="bg-[#F1F5F9] min-h-screen">
          <div dir="ltr" className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {children}
          </div>
        </main>
      </div>

      {/* Sidebar */}
      <DashboardSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </div>
  );
}

export default DashboardLayout;
