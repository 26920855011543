import Category from '../model/category';
import { ComputerDesktopIcon } from '@heroicons/react/24/outline';

// eslint-disable-next-line unused-imports/no-unused-vars
const CategotyItem = (item: Category, className: string) => {
  return (
    <div className="bg-[#5444A6]/[0.1] justify-start w-full rounded-sm py-8 px-5">
      <div className="p-2 w-12 h-12 rounded-md bg-[#5444A6]/[0.2]">
        <ComputerDesktopIcon className="h-8 text-[#5444A6]"></ComputerDesktopIcon>
      </div>
      <div className="w-full h-full flex flex-col justify-between">
        <div>
          <div className="w-full text-[#111111] font-bold mb-2 text-right">{item.title}</div>
          <div className=" text-sm text-[#CACDCD] text-right w-full">
            What is not clear? When I inserted your code, in my pagination with a fraction, the
            number of slides is displayed more than it actually is, namely 6/10, etc.
          </div>
        </div>
      </div>
    </div>
  );
};
export default CategotyItem;
