import InputField from '../../../components/shared/InputField';
import Button from '../../../components/shared/Button';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { btnTypes } from '../../../constants/data';
import { useState } from 'react';
import axios from 'axios';
function Login() {
  const [loading, setLoading] = useState(false);
  const userSchema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup
      .string()
      .required('No password provided.')
      .min(8, 'Password is too short - should be 8 chars minimum.')
      .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(userSchema)
  });

  const onSubmit = async (data: any) => {
    setLoading(true);

    setLoading(false);
  };

  const onAthunticateGoogle = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/google`);
    console.log('lllllll ' + JSON.stringify(response));
  };

  const onAthunticateFacebook = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/auth/facebook`);
    console.log('lllllll ' + JSON.stringify(response));
  };

  return (
    <section className="h-screen">
      <div className="container px-6 py-12 h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <div className="md:w-8/12 lg:w-6/12 mb-12 md:mb-0">
            <img
              src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
              className="w-full"
              alt="Phone image"
            />
          </div>
          <div className="md:w-8/12 lg:w-5/12 lg:ml-20">
            <div className="w-full pb-10">
              <form id="add-collection" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
                <div className="overflow-visible relative bottom-0 w-full sm:rounded-md ">
                  <div className="mt-6">
                    <InputField
                      name="email"
                      type="gmail"
                      placeholder="enter your gmail"
                      register={register}
                      errors={errors?.email}
                      className=" h-[55px] w-full rounded-lg border border-[#D2D2D2]"
                    />
                  </div>
                  <div className="mt-6">
                    <InputField
                      name="password"
                      type="password"
                      placeholder="your password "
                      register={register}
                      errors={errors?.password}
                      className=" h-[55px] w-full rounded-lg border border-[#D2D2D2]"
                    />
                  </div>
                  <div className="flex justify-between items-center mb-6">
                    <div className="form-group form-check">
                      <input
                        type="checkbox"
                        className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                        id="exampleCheck3"
                        checked
                      />
                      <label
                        className="form-check-label inline-block text-gray-800"
                        htmlFor="exampleCheck2">
                        Remember me
                      </label>
                    </div>
                    <a
                      href="#!"
                      className="text-blue-600 hover:text-blue-700 focus:text-blue-700 active:text-blue-800 duration-200 transition ease-in-out">
                      Forgot password?
                    </a>
                  </div>
                  <div className=" flex gap-x-5 mt-6">
                    <Button
                      type="submit"
                      className="w-full"
                      btnType={btnTypes.primary}
                      loading={loading}>
                      <div className="flex justify-center">Sign Up</div>
                    </Button>
                  </div>
                  <div className="flex items-center my-4 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                    <p className="text-center font-semibold mx-4 mb-0">OR</p>
                  </div>
                  <div className=" flex gap-x-5 mt-6">
                    <Button
                      onClick={() => onAthunticateGoogle()}
                      className="w-full"
                      btnType={btnTypes.primary}
                      loading={loading}>
                      <div className="flex justify-center">Gmail</div>
                    </Button>
                  </div>
                  <div className=" flex gap-x-5 mt-6">
                    <a className="button facebook" href="/login/federated/facebook">
                      Sign in with Facebook
                    </a>
                    <Button
                      onClick={() => onAthunticateFacebook()}
                      className="w-full"
                      btnType={btnTypes.primary}
                      loading={loading}>
                      <div className="flex justify-center">Facebook</div>
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Login;
