import { useEffect, useState } from 'react';
import { showCategories } from '../../../actions/categories';
import Category from '../../../components/model/category';
import AddCategoryModal from '../../../components/modal/addCategoryModal';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useDelete } from '../../../actions/categories';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/solid';
import ConfirmModal from '../../../components/modal/confirmModal';
import { ModalType } from '../../../constants/data';
import Spinner from '../../../components/shared/Spinner';
import { PaginationModel } from '../../../components/model/PaginationModel';
import Pagination from '../../../components/shared/Pagination';
const ShowCategories = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [defaultCategory, setDefaultCategory] = useState<Category>();
  const [pagination, setPagination] = useState<PaginationModel>({ page: 1, limit: 10 });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState<number | null | undefined>(0);
  useEffect(() => {
    fetchData(pagination?.page || 1, pagination?.limit || 10).catch(console.error);
  }, []);

  const fetchData = async (page: number, limit: number) => {
    const result = await showCategories(page, limit);
    setPagination({
      totalPages: result.data.totalPages,
      limit: result.data.limit,
      totalDocs: result.data.totalDocs,
      page: result.data.page,
      pagingCounter: result.data.pagingCounter,
      hasPrevPage: result.data.hasPrevPage,
      hasNextPage: result.data.hasNextPage,
      prevPage: result.data.hasNextPage,
      nextPage: result.data.hasNextPage
    });
    setCategories(result.data.docs);
  };

  const onCategoryAdded = async (result: boolean) => {
    if (result) {
      await fetchData(pagination.page || 1, pagination.limit || 10).catch(console.error);
    }
  };

  const onAddCategory = async () => {
    setDefaultCategory(undefined);
    setIsOpen(true);
  };

  const onDeleteConfirm = async () => {
    setShowDeleteModal(false);
    setDeleteLoader(selectedCategory?._id || 0);
    const result = await useDelete(selectedCategory?._id + '');
    if (result) {
      setCategories(categories.filter((category) => category._id !== result.data._id));
    }
    setDeleteLoader(0);
  };

  const onDeleteCategory = async (category: Category) => {
    setSelectedCategory(category);
    setShowDeleteModal(true);
  };

  const onEditCategory = async (category: Category) => {
    setDefaultCategory(category);
    setIsOpen(true);
  };

  const onPaginateChange = async (pageNumber: number) => {
    fetchData(pageNumber, pagination.limit || 10);
  };

  return (
    <div>
      <div className="sm:flex sm:justify-between sm:items-center mb-8">
        {/* Left: Avatars */}
        {/* <DashboardAvatars /> */}

        {/* Right: Actions */}
        <div className="flex justify-between w-full gap-2">
          {/* Add view button */}
          <button
            onClick={() => onAddCategory()}
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
            <div className=" flex gap-x-2">
              <PlusIcon className="h-6"></PlusIcon> <span className="mt-1">Add New Category</span>
            </div>
          </button>

          <div className="mt-2 font-bold text-2xl">New Category</div>
          {/* Filter button */}
          {/* <FilterButton /> */}
        </div>
      </div>
      <div className="w-full">
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="bg-white inline-block min-w-full border border-[#E2E8F0] rounded-sm overflow-hidden">
            <div className="w-full bg-white p-6 text-right border-b border-b-[#E2E8F0]">
              <span className="text-[#94A3B8] text-lg">230</span>
              <span className="text-[#1E293B] text-2xl ml-4">All Category</span>
            </div>
            <table className="min-w-full leading-normal">
              <thead className="">
                <tr className="bg-[#F8FAFC] py-4 text-right text-sm font-semibold text-[#64748B] uppercase tracking-wider">
                  <th className="px-5 py-5 border-b border-b-[#E2E8F0] text-center">Action</th>
                  <th className="px-5 py-5 border-b border-b-[#E2E8F0]">Color</th>
                  <th className="px-5 py-5 border-b border-b-[#E2E8F0]">Description</th>
                  <th className="px-5 py-5 border-b border-b-[#E2E8F0] ">Title</th>
                  <th className="px-5 py-5 border-b border-b-[#E2E8F0] ">Icon</th>
                  <th className="px-5 py-5 border-b border-b-[#b6c4d6] ">No</th>
                </tr>
              </thead>
              <tbody>
                {categories?.map((category, index) => {
                  return (
                    <tr
                      key={index}
                      className="text-right bg-white text-sm border-b border-b-[#E2E8F0] ">
                      <td className="px-5 py-5 flex justify-center">
                        <p className="whitespace-no-wrap flex gap-x-2 pt-2">
                          <PencilSquareIcon
                            onClick={() => onEditCategory(category)}
                            className="h-5 text-[#94A3B8] hover:cursor-pointer hover:text-[#1E293B]"></PencilSquareIcon>
                          {category._id === deleteLoader ? (
                            <Spinner className="h-5 w-5"></Spinner>
                          ) : (
                            <TrashIcon
                              onClick={() => onDeleteCategory(category)}
                              className="h-5 text-[#F43F5E] hover:cursor-pointer hover:text-red-700"></TrashIcon>
                          )}
                        </p>
                      </td>
                      <td className="px-5 py-5">
                        <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                          <span
                            aria-hidden
                            className="absolute inset-0 opacity-50 rounded-full"></span>
                          <span
                            style={{
                              backgroundColor: category.color + '23',
                              padding: 10,
                              color: category.color,
                              borderRadius: '100px'
                            }}
                            className="relative">
                            {category.color}
                          </span>
                        </span>
                      </td>
                      <td className="px-5 py-5">
                        <p className="text-[#475569] whitespace-no-wrap">{category.description}</p>
                      </td>
                      <td className="px-5 py-5 ">
                        <p className="text-[#1E293B] font-semibold whitespace-no-wrap">
                          {category.title}
                        </p>
                      </td>
                      <td className="px-5 py-5">
                        <div className="flex justify-end">
                          <div className="flex-shrink-0 w-10 h-10">
                            <img
                              className="w-full h-full object-fill rounded-full"
                              src={category?.icon}
                              alt=""
                            />
                          </div>
                        </div>
                      </td>
                      <td className="px-5 py-5">
                        <p className="text-[#475569] font-semibold whitespace-no-wrap">
                          {index + 1}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div>
            <Pagination pagination={pagination} onChange={onPaginateChange}></Pagination>
          </div>
        </div>

        <ConfirmModal
          title="Upgrade your Subscription?"
          isOpen={showDeleteModal}
          typeModal={ModalType.danger}
          onConfirm={onDeleteConfirm}
          setIsOpen={() => setShowDeleteModal(!showDeleteModal)}
          description="Semper eget duis at tellus at urna condimentum mattis pellentesque lacus suspendisse faucibus interdum."></ConfirmModal>
        <AddCategoryModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          defaultCategory={defaultCategory}
          onAdded={onCategoryAdded}></AddCategoryModal>
      </div>
    </div>
  );
};
export default ShowCategories;
