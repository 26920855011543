const Hero = () => {
  return (
    <div
      className="w-full overflow-hidden absolute top-0 bg-blue h-[768px] z-20 px-8 xl:px-16 mx-auto bg-[#FEF0EF]"
      id="about">
      <div className=" max-w-6xl mx-auto mt-16">
        <div className="grid relative grid-flow-row sm:grid-flow-col grid-rows-2 md:grid-rows-1 gap-x-10 sm:grid-cols-2 gap-8 py-6 sm:py-16 ">
          <div className="flex w-full">
            <div className="h-full w-full">
              <img src="assets/images/hero/hero-image.svg" className="h-full w-full" />
            </div>
          </div>
          <div className="flex flex-col justify-center items-start text-right row-start-2 sm:row-start-1">
            <h1 className="w-full text-right text-2xl lg:text-3xl xl:text-4xl text-secondary leading-normal font-digikalal">
              آرزوهایتان را به موفقیت تبدیل کنید
            </h1>
            <p className="text-secondary mt-4 mb-6">
              choose from over 100.000 online video courses with new additions published every mont.
            </p>
            <div className="p-3 rounded-md text-white bg-green-700">create on Search box</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Hero;
