import ArrowLongRightIcon from '@heroicons/react/24/solid/ArrowLongRightIcon';
import Layout from '../../../components/public/layout/PblicLayout';
const BlogDetails = () => {
  const blogs = [
    {
      id: 1,
      title: 'First Title ',
      description: 'Our members are so impressed. Its intuitive. Its clean.',
      fullName: 'Sabo Masties',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-frame_23-2148631040.jpg?w=740&t=st=1664532033~exp=1664532633~hmac=d872cf03ec8ed95002c95e0ae636b10bfdd01a8d343cce9dc00bf48f9e8f4a51'
    },
    {
      id: 2,
      title: 'Second Title',
      description: '“Our members are so impressed. distraction free. If you are building.',
      fullName: 'Margin Gesmu',
      image:
        'https://img.freepik.com/free-photo/top-view-hands-holding-elements-shape-hearts_23-2148631042.jpg?w=740&t=st=1664532035~exp=1664532635~hmac=3ebc1b79279b3dd980a5374409f30381d93e1cf86e9b20bb5b2d74ab30506ef5'
    },
    {
      id: 3,
      title: 'Third Title',
      description: '“Our members are so impressed.building a community',
      fullName: 'William Smith',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-globe_23-2148630972.jpg?w=740&t=st=1664532005~exp=1664532605~hmac=7b3aac03f2d9a57be319dd907290bc3686a7e173642d5bbe1f43872bfd9235aa'
    }
  ];
  return (
    <div className="w-full">
      <Layout>
        <div className=" max-w-6xl mx-auto my-28">
          <div className="text-primary text-xs uppercase font-bold">psychology</div>
          <h1 className="text-secondary text-5xl">
            New Technology Make for Better Dental Operation
          </h1>
          <div className="flex mt-10">
            <div>
              <img
                src="https://img.freepik.com/free-photo/horizontal-shot-pretty-dark-skinned-woman-with-afro-hairstyle-has-broad-smile-white-teeth-shows-something-nice-friend-points-upper-right-corner-stands-against-wall_273609-16442.jpg?w=1060&t=st=1664917473~exp=1664918073~hmac=f75ac16a60f668643da7d63a56481fcebb75fb60071289eec984a1888e6f3f77"
                alt=""
                className=" w-12 h-12 rounded-full object-cover"
              />
            </div>
            <div className="ml-3">
              <h2 className=" text-base font-bold text-secondary">Robert Fox</h2>
              <div className="text-secondary/[0.5] text-xs font-medium">12 August 2021</div>
            </div>
          </div>
          <div className="">
            <p className="text-secondary/[0.5] text-base font-medium mt-10">
              The paper discusses the use of epoxy resins in construction and repair of offshore
              concrete structures. Typical properties of resin systems are described, and the range
              of conditions encountered in practice and the development of a wide range of epoxy
            </p>
          </div>
          <div className="mt-20">
            <img
              src="https://img.freepik.com/free-photo/diverse-friends-students-shoot_53876-47012.jpg?w=1060&t=st=1664918031~exp=1664918631~hmac=93384516955ae5ee695309228f83322e4968595020f2fd2b777ddf0eb41d2605"
              alt=""
              className="w-full h-[500px] rounded-[32px] object-cover"
            />
          </div>
          <div>
            <div className="flex flex-wrap mb-5 pt-24">
              <div className="w-full px-4">
                <div className="mx-auto mb-[60px] max-w-[620px] text-center">
                  <div className="w-full py-2 text-secondary text-center font-medium text-4xl">
                    Stay updated with our latest news
                  </div>
                  <div className=" w-32 h-1 bg-primary rounded-sm mx-auto mt-4"></div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="grid relative lg:grid-cols-3 md:grid-cols-2 sm:px-10 sm:grid-cols-1 gap-7 z-10 max-w-6xl mx-auto">
                {blogs.map((blog) => {
                  return (
                    <div
                      className="bg-white border border-secondary/[0.1] shadow-[10px_40px_50px_rgba(229,233,246,0.5)] hover:bg-slate-100 hover:cursor-pointer flex flex-col rounded-[32px]"
                      key={blog.id}>
                      <div className="relative w-full h-64 p-4">
                        <img
                          src={blog.image}
                          className="object-cover h-full w-full rounded-[32px]"></img>
                        <div className=" absolute text-center top-8 left-8 h-[60px] py-1 w-[60px] bg-white rounded-2xl">
                          <div className="text-secondary text-lg font-bold">28</div>
                          <div className="text-secondary/[0.6] text-sm font-bold">Jan</div>
                        </div>
                      </div>
                      <div className="px-6">
                        <p className="text-primary text-xs py-1">Category</p>
                        <p className="text-secondary text-lg font-bold">{blog.title}</p>
                        <p className="text-secondary py-1 opacity-50 font-medium text-base">
                          {blog.description}
                        </p>
                        <div className="mb-5 flex text-primary">
                          <span>read more</span>
                          <ArrowLongRightIcon className="w-7 ml-3"></ArrowLongRightIcon>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default BlogDetails;
