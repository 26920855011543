import ArrowLongRightIcon from '@heroicons/react/24/solid/ArrowLongRightIcon';
import Layout from '../../../components/public/layout/PblicLayout';
const ShowBlogs = () => {
  const blogs = [
    {
      id: 1,
      title: 'First Title ',
      description: 'Our members are so impressed. Its intuitive. Its clean.',
      fullName: 'Sabo Masties',
      image:
        'https://img.freepik.com/free-vector/fear-missing-out-concept-with-woman_23-2148656619.jpg?w=740&t=st=1664912347~exp=1664912947~hmac=f954e1b04a775399732e2b9587939dd81bcbaa287464144a418f566b9c994dd2'
    },
    {
      id: 2,
      title: 'Second Title',
      description: '“Our members are so impressed. distraction free. If you are building.',
      fullName: 'Margin Gesmu',
      image:
        'https://img.freepik.com/free-vector/fomo-concept-illustration_23-2148676878.jpg?w=740&t=st=1664912358~exp=1664912958~hmac=9f829b489a577a67770dc48ef09e642f2022d854c10f971213171673547d0dfa'
    },
    {
      id: 3,
      title: 'Third Title',
      description: '“Our members are so impressed.building a community',
      fullName: 'William Smith',
      image:
        'https://img.freepik.com/free-vector/group-therapy-illustration-concept_52683-45686.jpg?w=1060&t=st=1664912261~exp=1664912861~hmac=f77537c297770a9900a74733a9b9389653d17c9f54fe539428805503b12ecf76'
    },
    {
      id: 4,
      title: 'First Title ',
      description: 'Our members are so impressed. Its intuitive. Its clean.',
      fullName: 'Sabo Masties',
      image:
        'https://img.freepik.com/free-vector/mental-health-awareness-concept_52683-37916.jpg?w=740&t=st=1664912285~exp=1664912885~hmac=cde817baa8e173b2e47765085e1a1356da1dd9a91fa143647864c90ccac054ae'
    },
    {
      id: 5,
      title: 'Second Title',
      description: '“Our members are so impressed. distraction free. If you are building.',
      fullName: 'Margin Gesmu',
      image:
        'https://img.freepik.com/free-vector/mental-health-awareness-meditation-concept_23-2148537672.jpg?w=740&t=st=1664912290~exp=1664912890~hmac=c763f376da950ad951db86e058469fe93d00989ce369c7c10ac2f4a37df35d09'
    },
    {
      id: 6,
      title: 'Third Title',
      description: '“Our members are so impressed.building a community',
      fullName: 'William Smith',
      image:
        'https://img.freepik.com/free-vector/mental-health-awareness-concept_23-2148552125.jpg?w=740&t=st=1664912319~exp=1664912919~hmac=93914020f59e1cc984fddf13ecc10d2be363b8a4adbf84505f5fce1398653b46'
    }
  ];
  return (
    <Layout>
      <div className="w-full bg-white py-32">
        <div className="relative max-w-6xl mx-auto grid grid-cols-2">
          <div className="pt-24 pr-10">
            <h1 className="text-secondary text-4xl font-bold">News and Articles</h1>
            <p className="text-secondary mt-5 opacity-70 text-lg font-medium">
              Stays updated with our latest blog and news and get healthy tips & trick for oral
              health
            </p>
            <div className="pt-20 px-5">
              <div className=" h-16 border flex justify-between p-1 border-secondary/[0.1] rounded-2xl">
                <div className="pt-3 px-3">Search article or news...</div>
                <div className="583FBC h-full w-40 bg-primary pt-3 text-center text-white rounded-2xl">
                  Search
                </div>
              </div>
            </div>
          </div>
          <div className="relative px-10 py-5">
            <div className="bg-dark-pink h-[400px] w-[330px] rounded-[48px]"></div>
            <img
              className=" absolute top-24 left-28 h-[400px] rounded-[49px]"
              src="https://img.freepik.com/free-photo/pretty-african-american-female-model-keyboards-something-laptop-computer-connected-free-wireless-internet-cafe-writes-new-article-her-blog_273609-3192.jpg?w=1060&t=st=1664910653~exp=1664911253~hmac=cbe39e61aebeb5ffb245ee74181668c7669fce8e922939ef2ad1bdcf7c7d4c3b"></img>
          </div>
        </div>
        <div>
          <div className=" mt-40">
            <h1 className="text-5xl text-center text-secondary font-bold">
              Stay updated with our latest news
            </h1>
            <div className="grid mt-20 relative lg:grid-cols-3 md:grid-cols-2 sm:px-10 sm:grid-cols-1 gap-7 z-10 max-w-6xl mx-auto">
              {blogs.map((blog) => {
                return (
                  <div
                    className="bg-white border border-secondary/[0.1] shadow-[10px_40px_50px_rgba(229,233,246,0.5)] hover:bg-slate-100 hover:cursor-pointer flex flex-col rounded-[32px]"
                    key={blog.id}>
                    <div className="relative w-full h-64 p-4">
                      <img
                        src={blog.image}
                        className="object-cover h-full w-full rounded-[32px]"></img>
                      <div className=" absolute text-center top-8 left-8 h-[60px] py-1 w-[60px] bg-white rounded-2xl">
                        <div className="text-secondary text-lg font-bold">28</div>
                        <div className="text-secondary/[0.6] text-sm font-bold">Jan</div>
                      </div>
                    </div>
                    <div className="px-6">
                      <p className="text-primary text-xs py-1">Category</p>
                      <p className="text-secondary text-lg font-bold">{blog.title}</p>
                      <p className="text-secondary py-1 opacity-50 font-medium text-base">
                        {blog.description}
                      </p>
                      <div className="mb-5 flex text-primary">
                        <span>read more</span>
                        <ArrowLongRightIcon className="w-7 ml-3"></ArrowLongRightIcon>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className=" max-w-5xl mx-auto my-20">
          <div className="flex gap-2">
            <div className="h-[64px] w-[64px] text-center text-lg rounded-2xl bg-primary pt-5 text-white">
              1
            </div>
            <div className="h-[64px] w-[64px] text-center text-lg rounded-2xl border border-secondary/[0.1] bg-white pt-5 text-secondary">
              2
            </div>
            <div className="h-[64px] w-[64px] text-center text-lg rounded-2xl border border-secondary/[0.1] bg-white pt-5 text-secondary">
              3
            </div>
            <div className="h-[64px] w-[64px] text-center text-lg rounded-2xl border border-secondary/[0.1] bg-white pt-5 text-secondary">
              4
            </div>
            <div className="h-[64px] w-[64px] text-center text-lg rounded-2xl border border-secondary/[0.1] bg-white pt-5 text-secondary">
              5
            </div>
            <div className="h-[64px] w-[64px] text-center text-lg rounded-2xl border border-secondary/[0.1] bg-white pt-5 text-secondary">
              6
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ShowBlogs;
