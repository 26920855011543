import Rectange from '../../../assets/rectange';
const Guide = () => {
  return (
    <div className="w-full bg-white py-20 items-center">
      <div className="w-full">
        <div className="flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-5 max-w-[620px] text-center">
              <div className="w-full py-2 text-secondary text-center font-medium text-4xl">
                Dentalist is re-imagining what the dental experience
              </div>
              <div className=" w-32 h-1 bg-primary rounded-sm mx-auto mt-4"></div>
            </div>
          </div>
        </div>
        <div className=" max-w-6xl mx-auto">
          <div className="grid grid-cols-2 gap-x-10 w-full">
            <div className=" flex justify-end relative">
              <div className="w-full h-full pl-20 py-10 ">
                <Rectange height="h-full" width="w-full" bgColor="#DBEFFA"></Rectange>
              </div>
              <div className="absolute w-full h-full flex">
                <div className="grid grid-cols-8 w-full">
                  <div className="col-span-3 w-full">
                    <img
                      src="assets/images/guide/test.svg"
                      className="w-full h-full mt-[15%]"></img>
                  </div>
                  <div className="col-span-5 w-full py-20 px-10">
                    <div className="text-secondary font-bold text-xl">
                      The discharge of hundred of millions
                    </div>
                    <div className="text-secondary font-medium opacity-50 mt-5">
                      The discharge of hundred of millions of cubic metres of gas, “would result in
                      hundreds of thousands of tonnes of methane emissions”, he said, a gas which
                      has “80 times the planet-warming potency of carbon dioxide”. Finally, he said
                      the pipeline explosions also made “manifestly clear” just how vulnerable
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-end relative">
              <div className="w-full h-full pl-20 py-10">
                <Rectange height="h-full" width="w-full" bgColor="#DBDEFA"></Rectange>
              </div>
              <div className=" absolute w-full h-full flex">
                <div className="grid grid-cols-8 w-full">
                  <div className="col-span-3 w-full">
                    <img
                      src="assets/images/guide/test.svg"
                      className="w-full h-full mt-[15%]"></img>
                  </div>
                  <div className="col-span-5 w-full py-20 px-10">
                    <div className="text-secondary font-bold text-xl">
                      The discharge of hundred of millions
                    </div>
                    <div className="text-secondary font-medium opacity-50 mt-5">
                      The discharge of hundred of millions of cubic metres of gas, “would result in
                      hundreds of thousands of tonnes of methane emissions”, he said, a gas which
                      has “80 times the planet-warming potency of carbon dioxide”. Finally, he said
                      the pipeline explosions also made “manifestly clear” just how vulnerable
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-end relative">
              <div className="w-full h-full pl-20 py-10">
                <Rectange height="h-full" width="w-full" bgColor="#F6DBFA"></Rectange>
              </div>
              <div className=" absolute w-full h-full flex">
                <div className="grid grid-cols-8 w-full">
                  <div className="col-span-3 w-full">
                    <img
                      src="assets/images/guide/test.svg"
                      className="w-full h-full mt-[15%]"></img>
                  </div>
                  <div className="col-span-5 w-full py-20 px-10">
                    <div className="text-secondary font-bold text-xl">
                      The discharge of hundred of millions
                    </div>
                    <div className="text-secondary font-medium opacity-50 mt-5">
                      The discharge of hundred of millions of cubic metres of gas, “would result in
                      hundreds of thousands of tonnes of methane emissions”, he said, a gas which
                      has “80 times the planet-warming potency of carbon dioxide”. Finally, he said
                      the pipeline explosions also made “manifestly clear” just how vulnerable
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex justify-end relative">
              <div className="w-full h-full pl-20 py-10">
                <Rectange height="h-full" width="w-full" bgColor="#FADBE2"></Rectange>
              </div>
              <div className=" absolute w-full h-full flex">
                <div className="grid grid-cols-8 w-full">
                  <div className="col-span-3 w-full">
                    <img
                      src="assets/images/guide/test.svg"
                      className="w-full h-full mt-[15%]"></img>
                  </div>
                  <div className="col-span-5 w-full py-20 px-10">
                    <div className="text-secondary font-bold text-xl">
                      The discharge of hundred of millions
                    </div>
                    <div className="text-secondary font-medium opacity-50 mt-5">
                      The discharge of hundred of millions of cubic metres of gas, “would result in
                      hundreds of thousands of tonnes of methane emissions”, he said, a gas which
                      has “80 times the planet-warming potency of carbon dioxide”. Finally, he said
                      the pipeline explosions also made “manifestly clear” just how vulnerable
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Guide;
