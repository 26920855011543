import { ArrowPathIcon } from '@heroicons/react/24/solid';
import React, { useContext } from 'react';
import { SocketContext } from '../../context/SocketContext';
function VideoPalyer() {
  const {
    name,
    callAccepted,
    myVideo,
    me,
    leaveCall,
    callUser,
    userVideo,
    callEnded,
    stream,
    call
  } = useContext(SocketContext);

  return (
    <div>
      {callAccepted && !callEnded ? (
        <video
          playsInline
          ref={userVideo}
          autoPlay
          className="h-[500px] object-cover bg-primary w-full rounded-[20px]"></video>
      ) : (
        <div className="h-[500px] object-cover text-center pt-[20%] text-white bg-primary w-full rounded-[20px]">
          {name} is not in the call pleace waith...
        </div>
      )}

      {stream && (
        <video
          playsInline
          autoPlay
          muted
          ref={myVideo}
          className="absolute h-36 w-56 bg-secondary object-cover rounded-lg bottom-20 right-4"></video>
      )}
      <div className=" absolute text-white top-4 flex justify-between w-full px-4">
        <div className="bg-[#131517]/[0.63] rounded-lg backdrop-blur-sm py-3 px-4">{name}</div>
        <div className="bg-[#131517]/[0.63] rounded-lg backdrop-blur-sm py-3 px-4">Record</div>
      </div>
      <div className=" absolute w-full bottom-6">
        <div className="flex gap-x-3 w-52 mx-auto ">
          <div>
            <ArrowPathIcon className=" bg-[#131517]/[0.63] text-white w-12 mt-1 h-12 rounded-full p-3"></ArrowPathIcon>
          </div>
          <div>
            {callAccepted && !callEnded ? (
              <ArrowPathIcon
                onClick={leaveCall}
                className=" bg-[#FB0242] text-white w-14 h-14 rounded-[20px] p-4"></ArrowPathIcon>
            ) : (
              <ArrowPathIcon
                onClick={() => callUser(me)}
                className=" bg-[#3dba17] text-white w-14 h-14 rounded-[20px] p-4"></ArrowPathIcon>
            )}
          </div>
          <div>
            <ArrowPathIcon className=" bg-[#131517]/[0.63] text-white w-12 mt-1 h-12 rounded-full p-3"></ArrowPathIcon>
          </div>
        </div>
      </div>
    </div>
  );
}
export default VideoPalyer;
