import { CREATE } from '../constants/actionType';

import * as api from '../api/index';

export const createPost = async (post: any) => {
  try {
    const { data } = await api.createPost(post);
    console.log('jjjjjj ' + JSON.stringify(data));
    // dispatch({ type: CREATE, payload: data });
  } catch (error: any) {
    console.log(error.message);
  }
};
