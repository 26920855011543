/* eslint-disable prettier/prettier */
import axios from 'axios';
import Category from '../components/model/category';

const REACT_APP_BACKEND_URL=process.env.REACT_APP_BACKEND_URL;
const url=`${REACT_APP_BACKEND_URL}/users`;
const categoryUrl = `${REACT_APP_BACKEND_URL}/categories`;

const options = {
    headers: { 'Content-Type': 'multipart/form-data' }
  };


// export const fetchPosts = () => axios.get(url);
export const createPost = (newUser: any) => axios.post(url, newUser);
// export const likePost = (id) => axios.patch(`${url}/${id}/likePost`);
// export const updatePost = (id, updatedPost) => axios.patch(`${url}/${id}`, updatedPost);
// export const deletePost = (id) => axios.delete(`${url}/${id}`);

export const fetchCategories = (page:number,limit:number) => axios.get(`${categoryUrl}?page=${page}&limit=${limit}`);
export const createCategory=(category:Category)=>axios.post(categoryUrl,category)
export const deleteCategory=(id:string)=>axios.delete(`${categoryUrl}/${id}`)
export const updateCategory=(id:string,category:any)=>axios.put(`${categoryUrl}/${id}`,category,options)
