import './advisors.css';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
const Advisors = () => {
  const advisors = [
    {
      id: 1,
      title: 'First Title ',
      description: 'Our members are so impressed. Its intuitive. Its clean.',
      fullName: 'Sabo Masties',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-frame_23-2148631040.jpg?w=740&t=st=1664532033~exp=1664532633~hmac=d872cf03ec8ed95002c95e0ae636b10bfdd01a8d343cce9dc00bf48f9e8f4a51'
    },
    {
      id: 2,
      title: 'Second Title',
      description: '“Our members are so impressed. distraction free. If you are building.',
      fullName: 'Margin Gesmu',
      image:
        'https://img.freepik.com/free-photo/top-view-hands-holding-elements-shape-hearts_23-2148631042.jpg?w=740&t=st=1664532035~exp=1664532635~hmac=3ebc1b79279b3dd980a5374409f30381d93e1cf86e9b20bb5b2d74ab30506ef5'
    },
    {
      id: 3,
      title: 'Third Title',
      description: '“Our members are so impressed.building a community',
      fullName: 'William Smith',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-globe_23-2148630972.jpg?w=740&t=st=1664532005~exp=1664532605~hmac=7b3aac03f2d9a57be319dd907290bc3686a7e173642d5bbe1f43872bfd9235aa'
    },
    {
      id: 4,
      title: 'First Title ',
      description: 'Our members are so impressed. Its intuitive. Its clean.',
      fullName: 'Sabo Masties',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-frame_23-2148631040.jpg?w=740&t=st=1664532033~exp=1664532633~hmac=d872cf03ec8ed95002c95e0ae636b10bfdd01a8d343cce9dc00bf48f9e8f4a51'
    },
    {
      id: 5,
      title: 'Second Title',
      description: '“Our members are so impressed. distraction free. If you are building.',
      fullName: 'Margin Gesmu',
      image:
        'https://img.freepik.com/free-photo/top-view-hands-holding-elements-shape-hearts_23-2148631042.jpg?w=740&t=st=1664532035~exp=1664532635~hmac=3ebc1b79279b3dd980a5374409f30381d93e1cf86e9b20bb5b2d74ab30506ef5'
    },
    {
      id: 6,
      title: 'Third Title',
      description: '“Our members are so impressed.building a community',
      fullName: 'William Smith',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-globe_23-2148630972.jpg?w=740&t=st=1664532005~exp=1664532605~hmac=7b3aac03f2d9a57be319dd907290bc3686a7e173642d5bbe1f43872bfd9235aa'
    },
    {
      id: 5,
      title: 'Second Title',
      description: '“Our members are so impressed. distraction free. If you are building.',
      fullName: 'Margin Gesmu',
      image:
        'https://img.freepik.com/free-photo/top-view-hands-holding-elements-shape-hearts_23-2148631042.jpg?w=740&t=st=1664532035~exp=1664532635~hmac=3ebc1b79279b3dd980a5374409f30381d93e1cf86e9b20bb5b2d74ab30506ef5'
    },
    {
      id: 6,
      title: 'Third Title',
      description: '“Our members are so impressed.building a community',
      fullName: 'William Smith',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-globe_23-2148630972.jpg?w=740&t=st=1664532005~exp=1664532605~hmac=7b3aac03f2d9a57be319dd907290bc3686a7e173642d5bbe1f43872bfd9235aa'
    }
  ];
  return (
    <div>
      <section id="testimonials">
        <div className="w-full">
          <div className="py-20 bg-white">
            <div className="container mx-auto px-6 md:px-12 xl:px-32">
              <div className="flex flex-wrap mb-5 pt-10">
                <div className="w-full px-4">
                  <div className="mx-auto mb-[60px] max-w-[620px] text-center">
                    <div className="w-full py-2 text-secondary text-center font-medium text-4xl">
                      List of All Coches
                    </div>
                    <div className=" w-32 h-1 bg-primary rounded-sm mx-auto mt-4"></div>
                    <p className="text-lg mt-5 text-secondary font-medium opacity-50 leading-relaxed">
                      There are many variations of passages of Lorem Ipsum available but the
                      majority have suffered alteration in some form.
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10 max-w-7xl mt-10 mx-auto">
                {advisors.map((advisor) => {
                  return (
                    <div key={advisor.id} className="flex items-center justify-center">
                      <div className="relative w-full">
                        <div className="relative bg-[#041434] text-gray-50 rounded-xl p-8 space-y-7">
                          <div className="h-2 w-20 bg-primary"></div>

                          <div className="text-5xl font-extrabold text-white mx-auto flex items-center space-x-3">
                            <div className="shrink-0">
                              <img
                                className="inline-block h-12 w-12 rounded-full ring-2 ring-white"
                                src={advisor.image}
                                alt=""
                              />
                            </div>
                            <div className="pt-2">
                              <div className=" text-lg font-bold text-white">Full Name</div>
                              <p className="text-white text-sm opacity-60 font-medium">Job title</p>
                            </div>
                          </div>

                          <p className="leading-snug text-white opacity-60 font-medium">
                            Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
                            officia deserunt mollit anim id est laborum.
                          </p>
                          <a
                            href="#"
                            className=" text-center bg-primary text-white hover:text-secondary  hover:bg-dark-blue  p-3 rounded-2xl font-bold tracking-wide flex">
                            <span className="w-full font-bold text-base">Book an appointment</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-wrap pt-24">
                <div className="w-full px-4">
                  <div className="mx-auto mb-[60px] max-w-[620px] text-center">
                    <div className="w-60 py-5 bg-primary/[.1] hover:cursor-pointer hover:bg-primary text-primary  hover:text-white rounded-2xl mx-auto">
                      <div className="flex flex-row">
                        <div className="basis-4/5 align-middle text-base font-bold">
                          View all Advisors
                        </div>
                        <div className="basis-1/5">
                          <ChevronRightIcon className="text-white bg-primary rounded-full  h-7 w-7   font-black"></ChevronRightIcon>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Advisors;
