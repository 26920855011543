import Layout from '../../../components/public/layout/PblicLayout';
import { SocketContext } from '../../../components/context/SocketContext';
import React, { useContext } from 'react';
import VideoPalyer from '../../../components/public/chat/VideoPlayer';
import SideBar from '../../../components/public/chat/SideBar';
import Notification from '../../../components/public/chat/Notification';
function Chat() {
  const { name } = useContext(SocketContext);
  return (
    <div>
      <Layout>
        <div className="max-w-7xl h-full mx-auto">
          <div className=" relative pt-20 mb-10 grid grid-cols-3 gap-x-10 bg-[]">
            <div className=" col-span-2 p-5 grid gap-y-8 grid-rows-14 h-full">
              <div className="flex row-span-2 justify-between ">
                <div className="bg-[#4285F4] row-span-2 rounded-lg grid grid-cols-2 py-3 px-4 gap-x-10">
                  <div className="text-white">Metting itle </div>
                  <div className="bg-black rounded-lg py-1 text-white text-center px-2">
                    8:00 AM{' '}
                  </div>
                </div>
                <div>right option</div>
              </div>
              <div className=" row-span-7 relative">
                <VideoPalyer></VideoPalyer>
              </div>
            </div>
            <div className=" relative col-span-1 w-full h-full bg-[#161D34] px-5 py-10 rounded-[20px]">
              <SideBar>
                <Notification></Notification>
              </SideBar>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
export default Chat;
