import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import axios from 'axios';
import TextAreaField from '../shared/TextAreaField';
import InputField from '../shared/InputField';
import Button from '../shared/Button';
import PropTypes, { InferProps } from 'prop-types';
import { SketchPicker } from 'react-color';
import { useUpdateCategory } from '../../actions/categories';
import { btnTypes } from '../../constants/data';
function AddCategoryModal({
  isOpen,
  defaultCategory,
  setIsOpen,
  onAdded
}: InferProps<typeof AddCategoryModal.propTypes>) {
  const [loading, setLoading] = useState(false);
  const [icon, setIcon] = useState<any>(null);
  const [categoryColor, setCategoryColor] = useState('#d0021b');
  const categorySchema = yup.object().shape({
    title: yup.string().required(),
    icon: yup.string(),
    description: yup.string().required()
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset
  } = useForm({
    resolver: yupResolver(categorySchema)
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('color', categoryColor);
    formData.append('icon', icon);
    formData.append('description', data.description);
    const options = {
      headers: { 'Content-Type': 'multipart/form-data' }
    };
    const result = await axios.post('http://localhost:3000/categories', formData, options);
    if (result) {
      reset();
      setIsOpen(false);
      onAdded(true);
      setIcon(null);
    }
    setLoading(false);
  };

  const onEdit = async (data: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('color', categoryColor);
    formData.append('description', data.description);
    if (icon !== null) {
      formData.append('icon', icon);
    }
    const result = await useUpdateCategory(defaultCategory._id, formData);
    if (result) {
      reset();
      setIsOpen(false);
      onAdded(true);
      setIcon(null);
    }
    setLoading(false);
  };

  const changeHandler = (event: any) => {
    setIcon(event.target.files[0]);
  };

  const handleChangeComplete = (color: any) => {
    setCategoryColor(color.hex);
  };

  useEffect(() => {
    if (defaultCategory) {
      setValue('title', defaultCategory?.title);
      setValue('description', defaultCategory?.description);
      setCategoryColor(defaultCategory.color);
    } else {
      setValue('title', null);
      setValue('description', null);
    }
  }, [defaultCategory]);

  return (
    <>
      <Transition appear show={isOpen || false} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-md bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 pt-6 px-10 text-gray-900">
                    {defaultCategory ? 'Edit Category' : 'New Category'}
                  </Dialog.Title>
                  <div className="w-full pb-10 px-10 ">
                    <form
                      id="add-collection"
                      onSubmit={handleSubmit(defaultCategory ? onEdit : onSubmit)}
                      action="#"
                      method="POST">
                      <div className="overflow-visible relative bottom-0 w-full sm:rounded-md ">
                        <div className="mt-6">
                          <InputField
                            name="title"
                            label={`Title `}
                            labelStyle="text-base font-semibold leading-9 -tracking-[0.02rem] mb-2"
                            type="text"
                            placeholder="enter your title "
                            register={register}
                            errors={errors?.title}
                            className=" h-[55px] w-full rounded-lg border border-[#D2D2D2]"
                          />
                        </div>
                        <div className="text-base font-semibold leading-9 -tracking-[0.02rem] mb-2">
                          Color
                        </div>
                        <SketchPicker
                          color={categoryColor}
                          onChangeComplete={handleChangeComplete}></SketchPicker>
                        <div className="flex justify-center mt-8">
                          <div className="w-full">
                            <div className="my-4">
                              <label className="inline-block mb-2 text-gray-500">
                                Upload Image(jpg,png,svg,jpeg)
                              </label>
                              <div className="flex items-center justify-center w-full">
                                <label className="flex flex-col w-full h-32 border-4 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                  <div className="flex flex-col items-center justify-center pt-7">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="w-12 h-12 text-gray-400 group-hover:text-gray-600"
                                      viewBox="0 0 20 20"
                                      fill="currentColor">
                                      <path
                                        fillRule="evenodd"
                                        d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                    <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                      Select a photo
                                    </p>
                                  </div>
                                  <input
                                    type="file"
                                    name="file"
                                    onChange={changeHandler}
                                    className="opacity-0"
                                  />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="mt-6">
                          <input type="file" name="file" onChange={changeHandler} />
                        </div> */}
                        <div>
                          <TextAreaField
                            name="description"
                            label="Description"
                            labelStyle="text-base font-semibold leading-9 -tracking-[0.02rem] mb-2 mt-6"
                            register={register}
                            rows={3}
                            errors={errors?.description}></TextAreaField>
                        </div>
                        <div className=" flex gap-x-5 mt-6">
                          <Button type="submit" btnType={btnTypes.primary} loading={loading}>
                            <div className="flex justify-center">
                              {defaultCategory ? 'Update' : 'Add Category'}
                            </div>
                          </Button>
                          <Button onClick={() => setIsOpen(false)} btnType={btnTypes.outline}>
                            Cancel
                          </Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

AddCategoryModal.propTypes = {
  isOpen: PropTypes.bool,
  defaultCategory: PropTypes.any,
  setIsOpen: () => void {},
  onAdded: () => void {}
};

export default AddCategoryModal;
