import './App.css';
import { ApolloProvider } from '@apollo/client';
import client from '../../pages/public/services/apollo-client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from '../../pages/errors/not-found';
import ShowChaoches from '../../pages/public/choaches/show-choaches';
import ChoachProfile from '../../pages/public/choaches/choach-profile';
import ShowBlogs from '../../pages/public/blog/show-blogs';
import BlogDetails from '../../pages/public/blog/blog-details';
import Home from '../../pages/public/home';
import Chat from '../../pages/public/choaches/chat';
import Login from '../../pages/public/login';
import SignUp from '../../pages/public/signup';
import { AuthProvider } from '../../authn/authn';
import Profile from '../../pages/profile';
import Dashboard from '../../pages/dashboard';
import DashboardLayout from '../dashboard/DashboardLayout';
import AddClass from '../../pages/dashboard/courses/addCourse';
import ShowCategories from '../../pages/dashboard/category/showCategories';

function App() {
  return (
    <div className="App w-full">
      <ApolloProvider client={client}>
        <AuthProvider>
          {/* <ContextProvider> */}
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="choaches" element={<ShowChaoches />}></Route>
              <Route path="choach/:slug" element={<ChoachProfile />}></Route>
              <Route path="blogs" element={<ShowBlogs />}></Route>
              <Route path="blogs/:slug" element={<BlogDetails />}></Route>
              <Route path="chat" element={<Chat></Chat>}></Route>
              <Route path="login" element={<Login></Login>}></Route>
              <Route path="sign-up" element={<SignUp></SignUp>}></Route>
              <Route path="profile" element={<Profile></Profile>}></Route>
              <Route
                path="new_course"
                element={
                  <DashboardLayout>
                    <AddClass></AddClass>
                  </DashboardLayout>
                }></Route>
              <Route
                path="categories"
                element={
                  <DashboardLayout>
                    <ShowCategories></ShowCategories>
                  </DashboardLayout>
                }></Route>
              <Route path="dashboard" element={<Dashboard></Dashboard>}></Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
          {/* </ContextProvider> */}
        </AuthProvider>
      </ApolloProvider>
    </div>
  );
}

export default App;
