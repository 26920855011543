import Advisors from '../../../components/public/advisors/advisors';
import Blogs from '../../../components/public/blogs/blogs';
import Guide from '../../../components/public/guide/guide';
import Hero from '../../../components/public/hero/hero';
import Layout from '../../../components/public/layout/PblicLayout';
import Questions from '../../../components/public/questions/questions';
import Services from '../../../components/public/services/services';
import AboutUS from '../../../components/public/aboutUs/aboutUs';
import ChooseCourse from '../../../components/public/choseCourses/chooseCourse';
import ChooseCategory from '../../../components/public/chooseCategory/chooseCategory';

const Home = () => {
  return (
    <Layout>
      <div>
        <Hero></Hero>
        <div className="relative mt-[768px]">
          <ChooseCourse></ChooseCourse>
          <ChooseCategory></ChooseCategory>
          <Guide></Guide>
          <Services></Services>
          <Advisors></Advisors>
          <Blogs></Blogs>
          <Questions></Questions>
        </div>
      </div>
    </Layout>
  );
};
export default Home;
