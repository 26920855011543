import { ArrowPathIcon } from '@heroicons/react/24/solid';
function SideBar({ children }: any) {
  return (
    <div>
      <div className="w-full gird grid-cols-10">
        <div className="col-span-2">
          <img
            className=" col-span-2 h-12 w-12 rounded-full object-cover"
            src="https://img.freepik.com/free-photo/cheerful-teenager-with-toothy-smile-afro-hairstyle-holds-modern-cell-phone-chats-online-with-boyfriend_273609-30470.jpg?w=1060&t=st=1665657882~exp=1665658482~hmac=d484beffd752f6eccc148800da83c440f9c7ea15e0d192aa05e8fb3611f177a0"
            alt=""
          />
        </div>
        <div className="col-span-8">
          <div className="text-white">Habib </div>
          <div className="bg-[#1E2745] text-white p-4">
            Anyone is up for illustrations. I think there are less relatable images according to our
            brand.
          </div>
        </div>
      </div>
      <div className="flex absolute bottom-3 w-full pr-10">
        <div className="bg-[#1E2745] rounded-[50px] w-full p-3">Write message</div>
        <div className=" w-12 ml-2 bg-[#1E2745] h-12 rounded-full p-3 text-white">
          <ArrowPathIcon></ArrowPathIcon>
        </div>
      </div>
    </div>
  );
}
export default SideBar;
