import GlassComponent from '../../shared/GlassComponent';
import { PhoneIcon } from '@heroicons/react/24/solid';
const Footer = () => {
  return (
    <div className="bg-[#191E46] text-white pt-44 pb-24">
      <div className="max-w-screen-xl w-full mx-auto px-6 sm:px-8 lg:px-16 grid grid-rows-6 sm:grid-rows-1 grid-flow-row sm:grid-flow-col grid-cols-3 sm:grid-cols-12 gap-4">
        <div className="row-span-2 sm:col-span-4 col-start-1 col-end-4 sm:col-end-5 flex flex-col items-start ">
          <div className="h-8 w-auto mb-6">
            <img src="/assets/logo.svg"></img>
          </div>
          <p className="mb-4 text-lg font-medium text-[#FFFFFF]/[0.6]">
            Dental Care Seattle is a top-rated local practice for cosmetic, preventative, and
            restorative dentistry on First Hill
          </p>
          <div className=" pt-12">
            <p className="text-[#ffffff]/[0.6] uppercase text-xs font-bold">FOllow us on</p>
            <div className="flex w-full mt-4 mb-8 -mx-2">
              <div className="mx-2 bg-white rounded-full items-center justify-center flex p-2 shadow-md">
                <div className="h-6 w-6">
                  <img src="/assets/Icon/facebook.svg"></img>
                </div>
              </div>
              <div className="mx-2 bg-white rounded-full items-center justify-center flex p-2 shadow-md">
                <div className="h-6 w-6">
                  <img src="/assets/Icon/twitter.svg"></img>
                </div>
              </div>
              <div className="mx-2 bg-white rounded-full items-center justify-center flex p-2 shadow-md">
                <div className="h-6 w-6">
                  <img src="/assets/Icon/instagram.svg"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" row-span-2 sm:col-span-2 sm:col-start-7 sm:col-end-10 flex flex-col">
          <p className="text-[#FFFFFF]/[0.6] mb-4 font-medium text-lg uppercase">Quick Links</p>
          <ul className="text-white ">
            <li className="my-2 hover:text-red-500 cursor-pointer transition-all">About US </li>
            <li className="my-2 hover:text-red-500 cursor-pointer transition-all">Services </li>
            <li className="my-2 hover:text-red-500 cursor-pointer transition-all">Blog </li>
            <li className="my-2 hover:text-red-500 cursor-pointer transition-all">Fag </li>
          </ul>
        </div>
        <div className="row-span-2 sm:col-span-2 sm:col-start-10 sm:col-end-13 flex flex-col">
          <p className=" mb-4 uppercase font-medium text-lg text-[#FFFFFF]/[0.6]">
            Contact & Information
          </p>
          <ul>
            <li className="my-5 transition-all">
              <GlassComponent
                title="Phone Number"
                value="+088 123 654 987"
                icon={<PhoneIcon></PhoneIcon>}></GlassComponent>
            </li>
            <li className="my-5 transition-all">
              <GlassComponent
                title="Phone Number"
                value="+088 123 654 987"
                icon={<PhoneIcon></PhoneIcon>}></GlassComponent>
            </li>
          </ul>
        </div>
      </div>
      <div className=" max-w-6xl mt-40 mx-auto">
        <div className="w-ful grid grid-cols-2 justify-between">
          <span className="text-[#ffffff]/[0.6] text-base font-medium">
            ©Dentalist. All Right Reserved
          </span>
          <div className="flex  flex-row-reverse gap-x-10">
            <div className="text-[#ffffff] text-base hover:cursor-pointer hover:text-[#ffffff]/[0.6]">
              Privacy Policy
            </div>
            <div className="text-[#ffffff] text-base hover:cursor-pointer hover:text-[#ffffff]/[0.6]">
              Terms of Use{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
