import { Navigate, useLocation } from 'react-router-dom';
import React from 'react';
// import { useGetMe } from '../hooks/user/useGetMe';
// import Loader from '../components/shared/loader';
// import { getAccountOption } from '../util/account';

const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;

interface AuthContextType {
  // user: any;
  getUser: () => any;
  getAccount: () => any;
  getAccountId: () => any;
  // fetchUser: () => void;
  signin: () => void;
  signout: () => void;
  refetch: () => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}

// AuthProvider is a component that wraps the entire app and provides the auth context
// to all of its children. On initial load, it will fetch the current user.
// TODO: integrate with Apollo client local state
export function AuthProvider({ children }: { children: React.ReactNode }) {
  // let [user, setUser] = React.useState<any>(null);

  //   const { loading, error, user, refetch } = useGetMe();

  const signin = () => {
    // refetch();
  };

  const refetch = () => {
    // refetch
  };

  const signout = () => {
    return fetch(`${strapiUrl}/authn/logout`, {
      method: 'POST',
      // requied in order to store/send auth cookie
      credentials: 'include'
    });
  };

  const getUser = () => {
    return 'user';
  };

  const getAccount = () => {
    return 'user account';
  };

  // Convenience function to get the account ID
  const getAccountId = () => {
    return 'user account id';
  };

  const value = { getUser, getAccount, getAccountId, signin, signout, refetch };

  // if (loading && !user) {
  //   return <Loader isLoading />;
  // }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

// RequireAuthn is a component that requires the user to be logged in. If the user is not logged in,
// it will redirect to /login.
export function RequireAuthn({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.getUser()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

/* AuthorizedRoute is a component that requires the user to have a specific permission via an 'enabledOption' in order to view a route.
e.g. usage:
<Route
  path="exhibitions"
  element={<AuthorizedRoute enabledOption="enableExhibitions"><Exhibitions /></AuthorizedRoute>}
/>

In the above example, the user must have the 'enableExhibitions' flag set to true on their account in order to view the exhibitions page.
*/
export function AuthorizedRoute({
  // path,
  // element,
  children,
  enabledOption
}: {
  // path: string;
  // element: JSX.Element;
  children: JSX.Element;
  enabledOption: string;
}) {
  const auth = useAuth();
  const location = useLocation();

  // if (!getAccountOption(auth.getAccount(), enabledOption)) {
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  // return <Navigate to="/" state={{ from: location }} replace />;
  // }

  return children;
  // return <Route path={path} element={element} />;
}
