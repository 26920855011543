import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import PropTypes, { InferProps } from 'prop-types';
import { useState } from 'react';
function InputField({
  defaultValue,
  errors,
  placeholder,
  register,
  onChange,
  name,
  label,
  suffix,
  maxLength = 'none',
  type = 'text',
  registerOptions = {},
  disabled = false,
  className = undefined,
  verificationText = '',
  labelStyle = ''
}: InferProps<typeof InputField.propTypes>) {
  const [togglePassword, setTogglePassword] = useState(type);
  return (
    <div>
      <label htmlFor={name || ''} className={`block font-poppins ${labelStyle}`}>
        {label}
      </label>
      <div className="mt-1 relative">
        <div className="block w-full">
          <input
            id={name}
            {...register(name, registerOptions)}
            onChange={onChange}
            placeholder={placeholder}
            type={togglePassword}
            defaultValue={defaultValue}
            disabled={disabled}
            maxLength={maxLength}
            className={
              className
                ? className
                : clsx(
                    errors?.message == null
                      ? 'focus:ring-primary-500 focus:border-primary-500 border-gray-300 '
                      : ' pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ',
                    'rounded-lg border border-[#D9D9D9]',
                    suffix ? 'w-1/2 mr-2 inline' : 'block w-full'
                  )
            }
          />
          {type === 'password' && (
            <div
              onClick={
                togglePassword === 'password'
                  ? () => setTogglePassword('text')
                  : () => setTogglePassword('password')
              }
              className=" absolute right-3 top-[9px] hover:cursor-pointer">
              {togglePassword === 'password' ? (
                <EyeIcon className="h-6"></EyeIcon>
              ) : (
                <EyeSlashIcon className="h-6"></EyeSlashIcon>
              )}
            </div>
          )}
          {suffix && <span className="inline text-gray-500 text-sm">{suffix}</span>}
        </div>
        {errors?.message ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        ) : (
          <div className=" font-poppins font-normal text-sm leading-5 mt-2 text-[#BCBCBC]">
            {verificationText}
          </div>
        )}
      </div>
      {errors?.message && (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors?.message}
        </p>
      )}
    </div>
  );
}

InputField.propTypes = {
  defaultValue: PropTypes.string,
  errors: PropTypes.any,
  placeholder: PropTypes.string,
  register: () => void {},
  onChange: () => void {},
  name: PropTypes.string,
  registerOptions: PropTypes.any,
  label: PropTypes.string,
  suffix: PropTypes.string,
  maxLength: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  verificationText: PropTypes.string,
  labelStyle: PropTypes.string
};

export default InputField;
