import Course from '../model/course';
import { EyeIcon, PlayCircleIcon, StarIcon } from '@heroicons/react/24/outline';
import { PlayIcon } from '@heroicons/react/24/solid';

const CourseItem = (item: Course) => {
  return (
    <div className="inline-block group hover:cursor-pointer">
      <div className="w-full rounded-lg transform overflow-hidden bg-white ease-in-out">
        <div className="grid grid-rows-2">
          <div className="h-[250px] w-full relative overflow-hidden">
            <img
              src={item?.image}
              className="w-full h-full transition duration-500 group-hover:scale-125 ease-in-out object-cover rounded-t-lg"
              alt=""
            />
            <div className="absolute h-16 w-16 top-[35%] left-[40%] rounded-full bg-white">
              <PlayIcon className="h-7 w-7 mx-auto mt-[18px]"></PlayIcon>
            </div>
          </div>
          <div className="w-full h-full p-4">
            {/* <div className="flex justify-between mx-3 border-b pb-2">
              <div className="flex gap-x-1">
                <span>امتیازها</span>
                <StarIcon className="h-5 mt-1"></StarIcon>
              </div>
              <div className="flex gap-x-1">
                <span>دیده شده</span>
                <EyeIcon className="h-5 mt-1"></EyeIcon>
              </div>
              <div className="flex gap-x-1">
                <span>درسها</span>
                <PlayCircleIcon className="h-5 mt-1"></PlayCircleIcon>
              </div>
            </div> */}
            <div className=" flex justify-between">
              <div className="text-[#E60808] bg-[#E60808]/[0.2] font-bold rounded-md px-3 py-1">
                {item.category}
              </div>
              <p className=" text-right text-[#212529] font-bold"></p>
              <div className="text-[#23DB81] font-bold">
                {item.price !== 0 ? 'Af' + item.price : 'Free'}
              </div>
            </div>
            <div className="w-full text-[#111111] font-bold mt-5">{item?.title}</div>
            <div className="text-[#B3BBC2] my-4">{item.lessons} Lesson</div>
            <button className="transition duration-0 hover:duration-150">Button A</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseItem;
