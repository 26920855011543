import { useContext } from 'react';
import { SocketContext } from '../../context/SocketContext';
function Notification() {
  const { answerCall, call, callAccepted } = useContext(SocketContext);
  return (
    <div>
      {call.isReceivingCall && !callAccepted && (
        <div>
          <h1>{call.name} is calling:</h1>
          <button className="bg-[#fe690677] rounded-lg text-white py-3 px-4" onClick={answerCall}>
            Answer
          </button>
        </div>
      )}
    </div>
  );
}
export default Notification;
