/* eslint-disable prettier/prettier */
import PropTypes, { InferProps } from 'prop-types';
import { PhoneIcon } from '@heroicons/react/24/solid';
import React from 'react';
function GlassComponent({ width, height, bgColor,icon,title,value }: InferProps<typeof GlassComponent.propTypes>) {
  return (
    <div className='flex'>
    <div className="h-16 w-16 rounded-2xl ">
    <div className=' relative h-16 w-16'>
      <svg
        width="66"
        height="66"
        viewBox="0 0 66 66"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect
          x="1"
          y="1"
          width="64"
          height="64"
          rx="16"
          fill="white"
          fillOpacity="0.2"
          stroke="url(#paint0_linear_401_628)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_401_628"
            x1="1"
            y1="1"
            x2="65"
            y2="65"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="white" />
            <stop offset="1" stopColor="white" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <div className='absolute top-0 left-0 pt-[9px] pl-[9px]'>
            <div className=' w-12 h-12 bg-white rounded-2xl p-[14px] '>
                <div className='h-5 w-5 text-primary'>{icon}</div>
            </div>
      </div>
    </div>
    </div>
    <div className='px-4 h-16 grid grid-rows-2 justify-between py-2'>
      <div className="text-[#FFFFFF]/[0.6] text-sm font-bold">{title}</div>
      <div className="text-[#FFFFFF] text-lg font-bold">{value}</div>
    </div>
  </div>
  );
}

GlassComponent.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string,
  icon:PropTypes.any,
  title:PropTypes.string,
  value: PropTypes.string,
};

export default GlassComponent;
