import ProfileLayout from '../../components/profile/ProfileLayout';
import ExploreCategory from '../../components/profile/ExploreCategory';
import PopularCourses from '../../components/profile/PopularClasses';
const Profile = () => {
  return (
    <div>
      <ProfileLayout>
        <div>
          <div className="w-full bg-[#E4F7FE] rounded-md h-[450px]"></div>
          <ExploreCategory></ExploreCategory>
          <PopularCourses></PopularCourses>
          <PopularCourses></PopularCourses>
        </div>
      </ProfileLayout>
    </div>
  );
};
export default Profile;
