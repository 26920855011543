import { useState } from 'react';
import clsx from 'clsx';
const Questions = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const questions = [
    {
      id: 1,
      question: 'Do you provide any free plan?',
      answer:
        'Lorem ipsum dolor sit amet, to the consectr adipiscing elit. Volutpat tempor to the condi mentum vitae vel purus.'
    },
    {
      id: 2,
      question: 'How to claim your 25% discount offer?',
      answer:
        'Lorem ipsum dolor sit amet, to the consectr adipiscing elit. Volutpat tempor to the condi mentum vitae vel purus.'
    },
    {
      id: 3,
      question: 'Do you provide any free plan?',
      answer:
        'Lorem ipsum dolor sit amet, to the consectr adipiscing elit. Volutpat tempor to the condi mentum vitae vel purus.'
    },
    {
      id: 4,
      question: 'How to claim your 25% discount offer?',
      answer:
        'Lorem ipsum dolor sit amet, to the consectr adipiscing elit. Volutpat tempor to the condi mentum vitae vel purus.'
    },
    {
      id: 5,
      question: 'Do you provide any free plan?',
      answer:
        'Lorem ipsum dolor sit amet, to the consectr adipiscing elit. Volutpat tempor to the condi mentum vitae vel purus.'
    },
    {
      id: 6,
      question: 'How to claim your 25% discount offer?',
      answer:
        'Lorem ipsum dolor sit amet, to the consectr adipiscing elit. Volutpat tempor to the condi mentum vitae vel purus.'
    }
  ];

  const changeQuestion = (currentIndex: number) => {
    currentIndex === selectedIndex ? setSelectedIndex(0) : setSelectedIndex(currentIndex);
  };
  return (
    <section className="relative pt-16 pb-20 bg-white overflow-hidden">
      <div className="relative z-10 container px-4 mx-auto">
        <div className="md:max-w-4xl mx-auto">
          <p className="mb-7  text-secondary text-center font-semibold uppercase tracking-px">
            Have any questions?
          </p>
          <h2 className="mb-16 text-5xl text-secondary  text-center font-bold font-heading tracking-px-n leading-none">
            Frequently Asked Questions
          </h2>
          <div className="mb-11 flex flex-wrap -m-1">
            <div className="w-full p-1">
              <div className="py-5 px-8 bg-opacity-60 shadow-10xl">
                {questions.map((question, index) => {
                  return (
                    <div
                      key={question.id}
                      className={clsx(
                        index == 0 ? 'border-t border-t-[#96A0B5]' : '',
                        'flex border-b border-b-[#96A0B5]  pt-4 px-6 justify-between'
                      )}>
                      <div
                        onClick={() => changeQuestion(question.id)}
                        className="flex-1 cursor-pointer">
                        <h3 className="mb-4 text-base text-[#121F3E] font-bold leading-normal">
                          {question.question}
                        </h3>
                        {question.id === selectedIndex && (
                          <p className="text-[#96A0B5] font-normal text-base pb-5">
                            {question.answer}
                          </p>
                        )}
                      </div>
                      <div className="w-auto">
                        <img
                          src="/assets/images/plus-sign.svg"
                          className={
                            selectedIndex === question.id
                              ? 'w-6 h-6 text-red-500'
                              : 'w-6 h-6 rotate-45 text-red-500'
                          }></img>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Questions;
