import { useState } from 'react';
import Header from './header';
import ProfileSidebar from './ProfileSidebar';
const ProfileLayout = ({ children }: any) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <div className="flex h-screen overflow-x-hidden">
      {/* Content area */}
      <div
        dir="rtl"
        className="relative flex flex-col flex-1 overflow-y-auto  scrollbar-thin scrollbar-thumb-[#AAAAAA] scrollbar-track-[#F0F0F0] overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div dir="ltr" className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto h-[1900px]">
            {children}
          </div>
        </main>
      </div>

      {/* Sidebar */}
      <ProfileSidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
    </div>
  );
};
export default ProfileLayout;
