/* eslint-disable prettier/prettier */
import { createContext, useState, useRef, useEffect, LegacyRef } from 'react';
import { io } from 'socket.io-client';
import Peer from 'simple-peer';

interface CallInterface {
  isReceivingCall: boolean;
  from: string;
  name: string;
  signal: string;
}

interface AppContextInterface {
  call: CallInterface;
  callAccepted: boolean;
  myVideo: any;
  userVideo: any;
  stream: any;
  name: string;
  setName: (name: string) => void;
  callEnded: boolean;
  me: string;
  callUser: (id: string) => void;
  leaveCall: () => void;
  answerCall: () => void;
}

const defaultState = {
  call: { isReceivingCall: false, from: '', name: '', signal: '' } as CallInterface,
  callAccepted: false,
  name: 'my name is habib',
  callEnded: false,
  me: ''
} as AppContextInterface;

const SocketContext = createContext<AppContextInterface>(defaultState);

const socket = io('http://localhost:3000');
// const socket = io('https://warm-wildwood-81069.herokuapp.com');

const ContextProvider = ({ children }: any) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [name, setName] = useState('hello');
  const [call, setCall] = useState({ isReceivingCall: true, from: '', name: '', signal: '' });
  const [me, setMe] = useState('me');

  const myVideo = useRef<HTMLVideoElement | null>(null);
  const userVideo = useRef<HTMLVideoElement | null>(null);
  const connectionRef = useRef<any | null>();

  useEffect(() => {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true }).then((currentStream) => {
      setStream(currentStream);
      myVideo!.current!.srcObject = currentStream;
    });
    socket.on('me', (id) => setMe(id));
    socket.on('callUser', ({ from, name: callerName, signal }) => {
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });
  }, []);

  const answerCall = () => {
    setCallAccepted(true);

    const peer = new Peer({ initiator: false, trickle: false, stream });

    peer.on('signal', (data) => {
      socket.emit('answerCall', { signal: data, to: call.from });
    });

    peer.on('stream', (currentStream) => {
      userVideo!.current!.srcObject = currentStream;
    });

    peer.signal(call.signal);

    connectionRef.current = peer;
  };

  const callUser = (id: string) => {
    const peer = new Peer({ initiator: true, trickle: false, stream });

    peer.on('signal', (data) => {
      socket.emit('callUser', { userToCall: id, signalData: data, from: me, name });
    });

    peer.on('stream', (currentStream) => {
      userVideo!.current!.srcObject = currentStream;
    });

    socket.on('callAccepted', (signal) => {
      setCallAccepted(true);

      peer.signal(signal);
    });
    if (connectionRef) {
      connectionRef.current = peer;
    }
  };

  const leaveCall = () => {
    setCallEnded(true);
    connectionRef.current?.destroy;
    window.location.reload();
  };

  return (
    <SocketContext.Provider
      value={{
        call,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        name,
        setName,
        callEnded,
        me,
        callUser,
        leaveCall,
        answerCall
      }}>
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
