import React from 'react';
import Spinner from './Spinner';
import { btnTypes } from '../../constants/data';
const baseClassName =
  'justify-center items-center text-base leading-7 disabled:text-[#8F8F8F] font-poppins outline-none text-md rounded-md px-6 py-2';
const primaryClassName = ' bg-[#6366F1] text-white hover:bg-[#4F46E5] ';
const secondaryClassName = ' bg-[#F43F5E] text-white hover:bg-[#F43FeE] ';
const outlineClassName =
  ' bg-white text-[#1E293B] border-2 border-[[#E2E8F0] hover:bg-[#F43F5E] hover:text-white hover:border-[#F43F5E]';
const defaultClassName = ' bg-[#6366F1] text-white hover:bg-[#4F46E5]  ';
type Props = {
  btnType?: any;
  loading?: boolean;
  className?: string;
  isDisable?: boolean; // All other props
  [x: string]: any;
  type?: 'button' | 'submit' | 'reset' | undefined;
};

const Button: React.FC<Props> = (props) => {
  const {
    loading,
    className = '',
    btnType = btnTypes.default,
    isDisable = false,
    children,
    type = 'button',
    ...rest
  } = props;
  let classes = baseClassName;

  const btnStyle =
    btnType === btnTypes.primary
      ? primaryClassName
      : btnType === btnTypes.secondary
      ? secondaryClassName
      : btnType === btnTypes.outline
      ? outlineClassName
      : defaultClassName;
  classes += `${btnStyle}`;
  classes += ` ${className}`;
  return (
    <button type={type} disabled={isDisable} className={classes} {...rest}>
      {loading ? <Spinner className="w-5 h-5" /> : children}
    </button>
  );
};

export default Button;
