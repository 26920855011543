import Category from '../components/model/category';
import Course from '../components/model/course';
import { DropdownModel } from '../components/model/DropdownModel';
export const courses: Course[] = [
  {
    id: 1,
    category: 'Python',
    title: 'Complte python bootcamp from zero to hero in Python',
    lessons: 10,
    price: 10,
    views: 20,
    discount: 4,
    image:
      'https://img.freepik.com/free-photo/close-up-african-kids-reading-together_23-2148860425.jpg?w=1060&t=st=1669457458~exp=1669458058~hmac=782a39759e27e8caa4cb6def6a8011f9ac6389615e256aaec833283a6214cf0a',
    bgColor: ''
  },
  {
    id: 2,
    category: 'English',
    price: 200,
    title: 'Complte python bootcamp from zero to hero in Python',
    lessons: 1,
    views: 100,
    discount: 10,
    image:
      'https://img.freepik.com/free-photo/books-imagination-still-life_23-2149082207.jpg?w=1060&t=st=1669457474~exp=1669458074~hmac=c3beb567dfcde0ea621c23bb85f34ecf724434ad85c0694b7ad7887a0e43b87e',
    bgColor: ''
  },
  {
    id: 3,
    category: 'German',
    price: 300,
    title: 'Complte python bootcamp from zero to hero in Python',
    lessons: 2,
    discount: 20,
    views: 60,
    image:
      'https://img.freepik.com/free-photo/girl-reading-from-magical-glowing-book_23-2149124131.jpg?w=826&t=st=1669457476~exp=1669458076~hmac=4886be2e11a2c445e399dba70af4bc82b3b53fb285d41deb04a2aad89a67fa66',
    bgColor: ''
  },
  {
    id: 4,
    category: 'Chiness',
    price: 500,
    discount: 70,
    views: 120,
    title: 'Complte python bootcamp from zero to hero in Python',
    lessons: 4,
    image:
      'https://img.freepik.com/free-photo/books-imagination-still-life_23-2149082166.jpg?w=1060&t=st=1669457547~exp=1669458147~hmac=c06b6fccf698abd8b1253e892105175b2cdcf1d1fb34fb2ec7155e99bf9ce327',
    bgColor: ''
  }
];

export const categories: Category[] = [
  { _id: 1, title: 'HTML', courses: 10, icon: '', color: '' },
  { _id: 2, title: 'Java', courses: 1, icon: '', color: '' },
  { _id: 3, title: 'JQuery', courses: 2, icon: '', color: '' },
  { _id: 4, title: 'PHP', courses: 4, icon: '', color: '' },
  { _id: 5, title: 'C#', courses: 70, icon: '', color: '' },
  { _id: 6, title: 'Flutter', courses: 15, icon: '', color: '' },
  { _id: 7, title: 'Java Script', courses: 16, icon: '', color: '' },
  { _id: 8, title: 'EN', courses: 14, icon: '', color: '' }
];

export const categoryOption: DropdownModel[] = [
  { id: 1, title: 'Delete', icon: '' },
  { id: 2, title: 'Edit', icon: '' }
];

export enum ModalType {
  info,
  success,
  danger
}

export enum btnTypes {
  primary,
  secondary,
  outline,
  default
}

export const modalTypes = {
  info: { color: 'blue' },
  danger: { color: 'blue' },
  success: { color: 'blue' }
};
