import PropTypes, { InferProps } from 'prop-types';
function Rectange({ width, height, bgColor }: InferProps<typeof Rectange.propTypes>) {
  return (
    <svg
      viewBox="0 0 434 358"
      className={`${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="1"
        d="M44.2904 41.7354C48.3276 17.6465 69.1778 0 93.6026 0H383.285C410.899 0 433.285 22.3858 433.285 50V308C433.285 335.614 410.899 358 383.285 358H50.3624C19.4471 358 -4.05989 330.225 1.05018 299.735L44.2904 41.7354Z"
        fill={`${bgColor}`}
      />
    </svg>
  );
}

Rectange.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  bgColor: PropTypes.string
};

export default Rectange;
