import { categories } from '../../constants/data';
import CategotyItem from '../shared/CategoryItem';
const ExploreCategory = () => {
  return (
    <div className="w-full">
      <div className="my-7 text-xl">
        Explore <span className="font-bold">Category</span>
      </div>
      <div className=" flex gap-x-6">
        {categories.map((category) => {
          return (
            <CategotyItem
              key={category._id}
              _id={category._id}
              title={category.title}
              courses={category.courses}
              description=""
              color=""
              icon=""></CategotyItem>
          );
        })}
      </div>
    </div>
  );
};

export default ExploreCategory;
