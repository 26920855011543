import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import { categories, courses } from '../../../constants/data';
import CourseItem from '../../shared/CourseItem';
import { useRef, useState } from 'react';
import {
  ArrowSmallLeftIcon,
  ArrowSmallRightIcon,
  ChevronDownIcon
} from '@heroicons/react/24/solid';
const ChooseCourse = () => {
  const swiperRef = useRef<SwiperType>();
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div>
      <section id="testimonials" className=" z-50">
        <div className="w-full relative bg-[#EFEFF7]">
          <div className="py-20 max-w-6xl mx-auto">
            <div className="flex justify-between">
              <div className=" flex gap-x-8">
                <a href="" className="bg-[#7443FF] py-2 px-3 rounded-sm text-white">
                  مشاهده همه
                </a>
                <div className="py-2 flex gap-x-2 text-[#908CA9] hover:cursor-pointer">
                  زبان <ChevronDownIcon className="h-5 "></ChevronDownIcon>
                </div>
              </div>
              <div className="text-2xl text-black">کورس خود را انتخاب کنید </div>
            </div>
            <div className="w-full mt-7">
              {/* <div className="grid grid-cols-3 gap-x-3">
                {courses.map((course) => (
                  <CourseItem
                    id={course.id}
                    category={course.category}
                    price={course.price}
                    lessons={course.lessons}
                    title={course.title}
                    image={course.image}
                    bgColor={course.bgColor}
                    key={course.id}></CourseItem>
                ))}
              </div> */}
              <Swiper
                slidesPerView={2}
                spaceBetween={5}
                dir="rtl"
                pagination={{
                  type: 'progressbar',
                  clickable: true
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                    spaceBetween: 20
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 40
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 50
                  }
                }}
                modules={[Navigation]}
                onBeforeInit={(swiper) => {
                  swiperRef.current = swiper;
                }}
                onRealIndexChange={(swiper) => {
                  setActiveIndex(swiper.realIndex);
                }}
                className="mySwiper">
                {courses.map((course) => (
                  <SwiperSlide key={course.id}>
                    <CourseItem
                      id={course.id}
                      category={course.category}
                      price={course.price}
                      lessons={course.lessons}
                      title={course.title}
                      image={course.image}
                      bgColor={course.bgColor}
                      key={course.id}></CourseItem>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div className="flex justify-between mt-5">
              <button
                className="bg-white rounded-md p-3"
                onClick={() => swiperRef.current?.slideNext()}>
                <ArrowSmallLeftIcon
                  className={`h-5 ${
                    activeIndex !== categories.length - 3 ? 'text-[#F8A149]' : 'text-[#B8B8BD]'
                  }`}></ArrowSmallLeftIcon>
              </button>
              <button
                className="bg-white rounded-md p-3"
                onClick={() => swiperRef.current?.slidePrev()}>
                <ArrowSmallRightIcon
                  className={`h-5 ${
                    activeIndex !== 0 ? 'text-[#F8A149]' : 'text-[#B8B8BD]'
                  } `}></ArrowSmallRightIcon>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ChooseCourse;
