import { ArrowLongRightIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
const Blogs = () => {
  const blogs = [
    {
      id: 1,
      title: 'First Title ',
      description: 'Our members are so impressed. Its intuitive. Its clean.',
      fullName: 'Sabo Masties',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-frame_23-2148631040.jpg?w=740&t=st=1664532033~exp=1664532633~hmac=d872cf03ec8ed95002c95e0ae636b10bfdd01a8d343cce9dc00bf48f9e8f4a51'
    },
    {
      id: 2,
      title: 'Second Title',
      description: '“Our members are so impressed. distraction free. If you are building.',
      fullName: 'Margin Gesmu',
      image:
        'https://img.freepik.com/free-photo/top-view-hands-holding-elements-shape-hearts_23-2148631042.jpg?w=740&t=st=1664532035~exp=1664532635~hmac=3ebc1b79279b3dd980a5374409f30381d93e1cf86e9b20bb5b2d74ab30506ef5'
    },
    {
      id: 3,
      title: 'Third Title',
      description: '“Our members are so impressed.building a community',
      fullName: 'William Smith',
      image:
        'https://img.freepik.com/free-photo/top-view-world-heart-day-concept-with-globe_23-2148630972.jpg?w=740&t=st=1664532005~exp=1664532605~hmac=7b3aac03f2d9a57be319dd907290bc3686a7e173642d5bbe1f43872bfd9235aa'
    }
  ];
  return (
    <section
      id="blogs"
      className="pt-20 pb-10 relative lg:pt-[120px] lg:pb-20 w-full bg-pink items-center">
      <div className="flex flex-wrap mb-5 pt-10">
        <div className="w-full px-4">
          <div className="mx-auto mb-[60px] max-w-[620px] text-center">
            <div className="w-full py-2 text-secondary text-center font-medium text-4xl">
              Most Popular Blog
            </div>
            <div className=" w-32 h-1 bg-primary rounded-sm mx-auto mt-4"></div>
            <p className="text-lg mt-5 text-secondary font-medium opacity-50 leading-relaxed">
              There are many variations of passages of Lorem Ipsum available but the majority have
              suffered alteration in some form.
            </p>
          </div>
        </div>
      </div>
      <div>
        <div className="absolute left-[8%] z-0 top-[25%]">
          <img src="/assets/images/blog/dots.svg" className=" relative z-0 h-28 w-32"></img>
        </div>
        <div className="absolute right-0 z-0 top-[50%]">
          <img
            src="/assets/images/blog/shape.svg"
            className="absolute h-[560px] w-[778px] z-0 -right-12"></img>
        </div>
      </div>
      <div className="">
        <div className="grid relative lg:grid-cols-3 md:grid-cols-2 sm:px-10 sm:grid-cols-1 gap-7 z-10 max-w-6xl mx-auto">
          {blogs.map((blog) => {
            return (
              <div
                className="bg-white border border-secondary/[0.1] shadow-[10px_40px_50px_rgba(229,233,246,0.5)] hover:bg-slate-100 hover:cursor-pointer flex flex-col rounded-[32px]"
                key={blog.id}>
                <div className="relative w-full h-64 p-4">
                  <img src={blog.image} className="object-cover h-full w-full rounded-[32px]"></img>
                  <div className=" absolute text-center top-8 left-8 h-[60px] py-1 w-[60px] bg-white rounded-2xl">
                    <div className="text-secondary text-lg font-bold">28</div>
                    <div className="text-secondary/[0.6] text-sm font-bold">Jan</div>
                  </div>
                </div>
                <div className="px-6">
                  <p className="text-primary text-xs py-1">Category</p>
                  <p className="text-secondary text-lg font-bold">{blog.title}</p>
                  <p className="text-secondary py-1 opacity-50 font-medium text-base">
                    {blog.description}
                  </p>
                  <div className="mb-5 flex text-primary">
                    <span>read more</span>
                    <ArrowLongRightIcon className="w-7 ml-3"></ArrowLongRightIcon>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-wrap pt-24">
        <div className="w-full px-4">
          <div className="mx-auto mb-[60px] max-w-[620px] text-center">
            <div className="w-60 py-5 bg-primary/[.1] hover:cursor-pointer hover:bg-primary text-primary  hover:text-white rounded-2xl mx-auto">
              <div className="flex flex-row">
                <div className="basis-4/5 align-middle text-base font-bold">View all Blog</div>
                <div className="basis-1/5">
                  <ChevronRightIcon className="text-white bg-primary rounded-full  h-7 w-7   font-black"></ChevronRightIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Blogs;
