import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon
} from '@heroicons/react/20/solid';
import { btnTypes, ModalType } from '../../constants/data';
import Button from '../shared/Button';

function ConfirmModal({
  isOpen,
  setIsOpen,
  title,
  description,
  typeModal,
  onConfirm
}: InferProps<typeof ConfirmModal.propTypes>) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      <Transition appear show={isOpen || false} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <div className="w-full ">
                    <div className="w-full flex flex-row-reverse gap-x-5">
                      <div className="w-10">
                        <div
                          className={`rounded-full h-10 w-10 text-center flex flex-col justify-center ${
                            typeModal === ModalType.info
                              ? ' bg-[#6366F1]/[0.2]'
                              : typeModal === ModalType.danger
                              ? 'bg-[#F43F5E]/[0.3]'
                              : 'bg-green-600/[0.2]'
                          }`}>
                          {typeModal === ModalType.info ? (
                            <InformationCircleIcon className="h-6 text-[#6366F1] mx-auto "></InformationCircleIcon>
                          ) : typeModal === ModalType.danger ? (
                            <ExclamationCircleIcon className="h-6 text-[#F43F5E] mx-auto "></ExclamationCircleIcon>
                          ) : (
                            <CheckCircleIcon className="h-6 text-green-500 mx-auto "></CheckCircleIcon>
                          )}
                        </div>
                      </div>
                      <div className="w-full text-right">
                        <div className="text-[#1E293B] font-medium text-2xl">{title}</div>
                        <div className="text-sm text-[#576476] mt-2">{description}</div>
                      </div>
                    </div>
                    <div className="w-full flex mt-7 gap-x-5">
                      <Button onClick={() => setIsOpen(false)} btnType={btnTypes.outline}>
                        Cancel
                      </Button>
                      <Button onClick={onConfirm} btnType={btnTypes.primary}>
                        Cofirm
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  typeModal: PropTypes.any,
  setIsOpen: () => void {},
  onConfirm: () => void {}
};

export default ConfirmModal;
