import Layout from '../../../components/public/layout/PblicLayout';
const ChoachProfile = () => {
  return (
    <div>
      <Layout>
        <div className="bg-white min-h-screen">
          <div className="h-[400px] bg-blue pt-28">
            <div className="max-w-6xl mx-auto grid grid-cols-2">
              <div>Details</div>
              <div>Image</div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default ChoachProfile;
