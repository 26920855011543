import Header from '../header/header';
import Footer from '../footer/footer';
const PublicLayout = ({ children }: any) => (
  <div>
    <div className="w-full">
      <Header></Header>
    </div>
    <div className="w-full">{children}</div>
    <Footer></Footer>
  </div>
);
export default PublicLayout;
