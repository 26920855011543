import * as api from '../api/index';
import Category from '../components/model/category';

export const showCategories = async (page: number, limit: number) => {
  try {
    const { data } = await api.fetchCategories(page, limit);
    return data;
  } catch (error: any) {
    // console.error(error);
  }
};

export const useUpdateCategory = async (id: string, category: any) => {
  try {
    const { data } = await api.updateCategory(id, category);
    return data;
  } catch (error: any) {
    // console.error(error);
  }
};

export const useDelete = async (id: string) => {
  try {
    const { data } = await api.deleteCategory(id);
    return data;
  } catch (error: any) {
    console.error(error);
  }
};

export const useAddCategory = async (category: Category) => {
  try {
    const { data } = await api.createCategory(category);
    return data;
  } catch (error: any) {
    console.error(error);
  }
};
