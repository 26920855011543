import { categories } from '../../../constants/data';
import CategotyItem from '../../shared/CategoryItem';
const ChooseCategory = () => {
  return (
    <div>
      <section id="testimonials" className=" z-50">
        <div className="w-full relative bg-white">
          <div className="py-20 max-w-6xl mx-auto">
            <div className="flex justify-between">
              <div className=" flex gap-x-8">
                <a href="" className="bg-[#F66962] py-2 px-3 rounded-sm text-white">
                  مشاهده همه
                </a>
              </div>
              <div className="text-2xl text-black">
                کورس های مورد نظر را از این دسته های بدست بیاورید
              </div>
            </div>
            <div className="w-full mt-7">
              <div className=" grid grid-cols-4 gap-x-6 gap-y-6">
                {categories.map((category) => (
                  <CategotyItem
                    _id={category._id}
                    icon={category.icon}
                    color="5444A6"
                    description=""
                    title={category.title}
                    courses={209}
                    key={category._id}></CategotyItem>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ChooseCategory;
