import React, { useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes, { InferProps } from 'prop-types';
import {
  VideoCameraIcon,
  Cog8ToothIcon,
  ChatBubbleOvalLeftIcon,
  ChartPieIcon,
  PlayCircleIcon,
  GlobeAltIcon,
  UsersIcon,
  CubeIcon
} from '@heroicons/react/24/outline';
import { useState } from 'react';

export interface MenuItem {
  id: number;
  title: string;
  icon?: any;
  isActive?: boolean;
  image?: string;
  onClick?: () => void;
}

export const Feed = (item: MenuItem) => {
  return (
    <div
      onClick={item?.onClick}
      className={` flex gap-x-6 rounded-md my-1 w-full px-5 py-4 cursor-pointer ${
        item?.isActive
          ? 'bg-gradient-to-l  from-indigo-600 to-[#7443FF] text-white '
          : ' bg-white text-[#888888] hover:text-[#7443FF]'
      }`}>
      <div className={item.isActive ? 'text-white' : ' text-[#7443FF]'}>{item?.icon}</div>
      <div>
        {item.title} <i data-feather="circle" className="h-6 w-6"></i>
      </div>
    </div>
  );
};

export const Setting = (item: MenuItem) => {
  return (
    <div
      onClick={item?.onClick}
      className={` flex gap-x-6 rounded-md my-1 w-full px-5 py-4 cursor-pointer bg-white text-[#888888] hover:text-[#005CFF]`}>
      <div className="text-[#B9C0C7]">{item?.icon}</div>
      <div>
        {item.title} <i data-feather="circle" className="h-6 w-6"></i>
      </div>
    </div>
  );
};

export const Author = (item: MenuItem) => {
  return (
    <div
      className={` flex justify-between gap-x-6 rounded-md my-1 w-full px-5 py-4 cursor-pointer bg-white text-[#888888] `}>
      <div className="text-[#B9C0C7] flex gap-x-2 hover:text-[#005CFF]">
        <img src={item.image} className=" w-10 h-10 object-cover rounded-full" alt="" />
        <div className="mt-2">{item.title}</div>
      </div>
      <div className="mt-4 bg-[#10D876] rounded-full h-2 w-2"></div>
    </div>
  );
};

const feeds: MenuItem[] = [
  { id: 1, title: 'Course Feed', icon: <CubeIcon className="h-6 w-6"></CubeIcon> },
  { id: 2, title: 'Followers', icon: <UsersIcon className="h-6 w-6"></UsersIcon> },
  {
    id: 3,
    title: 'Explore Channel',
    icon: <GlobeAltIcon className="h-6 w-6"></GlobeAltIcon>
  },
  {
    id: 4,
    title: 'Live Stream',
    icon: <PlayCircleIcon className="h-6 w-6"></PlayCircleIcon>
  },
  { id: 5, title: 'Save Course', icon: <VideoCameraIcon className="h-6 w-6"></VideoCameraIcon> }
];

const settings: MenuItem[] = [
  { id: 1, title: 'Settings', icon: <Cog8ToothIcon className="h-6 w-6"></Cog8ToothIcon> },
  { id: 2, title: 'Analytics ', icon: <ChartPieIcon className="h-6 w-6"></ChartPieIcon> },
  {
    id: 3,
    title: 'Chat',
    icon: <ChatBubbleOvalLeftIcon className="h-6 w-6"></ChatBubbleOvalLeftIcon>
  }
];

const authors: MenuItem[] = [
  { id: 1, title: 'Alirea Erfan', image: '/assets/profile/profile1.svg' },
  { id: 2, title: 'Habib Madadjo', image: '/assets/profile/profile11.svg' },
  { id: 3, title: 'Belal Shaban', image: '/assets/profile/profile3.svg' },
  { id: 4, title: 'Zaker Zakawat', image: '/assets/profile/profile14.svg' }
];

function ProfileSidebar({
  sidebarOpen,
  setSidebarOpen
}: InferProps<typeof ProfileSidebar.propTypes>) {
  const [activeMenu, setActiveMenu] = useState(2);
  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);
  const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true'
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }: any) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar?.current?.contains(target) || trigger?.current?.contains(target))
        return;
      setSidebarOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: any) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  useEffect(() => {
    localStorage.setItem('sidebar-expanded', sidebarExpanded + '');
    if (sidebarExpanded) {
      document?.querySelector('body')?.classList?.add('sidebar-expanded');
    } else {
      document?.querySelector('body')?.classList?.remove('sidebar-expanded');
    }
  }, [sidebarExpanded]);

  return (
    <div dir="rtl">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? 'opacity-100' : 'opacity-0  pointer-events-none'
        }`}
        aria-hidden="true"></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col journal-scroll lg:block absolute z-40 top-0 right-0 lg:static lg:right-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-auto lg:overflow-y-auto overflow-x-hidden w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-white p-4 transition-all duration-200 ease-in-out  ${
          sidebarOpen ? 'translate-x-0' : 'hidden translate-x-64'
        }`}>
        {/* Sidebar header */}
        <div className="flex lg:justify-end justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen || true}>
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current rotate-180"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block">
            <img className="w-40" src="/assets/logo.svg"></img>
          </NavLink>
        </div>

        <div className="mb-5 text-[#B7BEC5] pr-6">New Feeds</div>
        {feeds?.map((feed) => {
          return (
            <Feed
              key={feed.id}
              icon={feed?.icon}
              id={feed.id}
              isActive={feed.id === activeMenu ? true : false}
              onClick={() => setActiveMenu(feed?.id)}
              title={feed.title}></Feed>
          );
        })}
        <div className="my-5 text-[#B7BEC5] pr-6">Following Author</div>
        {authors?.map((author) => {
          return (
            <Author
              key={author.id}
              image={author?.image}
              id={author.id}
              title={author.title}></Author>
          );
        })}
        <div className="my-5 text-[#B7BEC5] pr-6">Account</div>
        {settings?.map((setting) => {
          return (
            <Setting
              key={setting.id}
              icon={setting?.icon}
              id={setting.id}
              isActive={setting.id === activeMenu ? true : false}
              onClick={() => setActiveMenu(setting?.id)}
              title={setting.title}></Setting>
          );
        })}
      </div>
    </div>
  );
}

ProfileSidebar.propTypes = {
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: () => void {}
};
export default ProfileSidebar;
