import Course from '../model/course';
import CourseItem from '../shared/CourseItem';
import { courses } from '../../constants/data';
const PopularCourses = () => {
  return (
    <div dir="rtl" className="w-full">
      <div className="flex flex-col bg-white m-auto p-auto">
        <h1 className="flex py-5 text-2xl text-gray-800">
          صنف های <span className="font-bold mx-2">ویژه </span>
        </h1>
        <div className="flex overflow-x-scroll mt-4 pb-10 hide-scroll-bar">
          <div className="flex flex-nowrap ml-5 ">
            {courses?.map((course: Course) => {
              return (
                <CourseItem
                  key={course.id}
                  id={course.id}
                  title={course.title}
                  category={course.category}
                  price={course.price}
                  lessons={course.lessons}
                  bgColor=""
                  image={course.image}></CourseItem>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularCourses;
