const Services = () => {
  const services = [
    {
      id: 1,
      description:
        'Choose your doctor from thousands of specialist, general, and trusted hospitals',
      title: 'Search doctor',
      image:
        'https://img.freepik.com/free-photo/relieved-ethnic-woman-stands-lotus-pose-tries-meditate-during-quarantine-lockdown-reaches-nirvana-does-yoga-keeps-eyes-closed-dressed-sweatshirt-mental-health-relaxation-lifestyle_273609-37722.jpg?w=1060&t=st=1664898158~exp=1664898758~hmac=bf1899126edaf724a6d417701ca24bad3c6417d233480991ffe8ef5cf9920f5e'
    },
    {
      id: 2,
      description: 'Buy  your medicines with our mobile application with a simple delivery system',
      title: 'Online pharmacy',
      image:
        'https://img.freepik.com/free-vector/mental-health-women-vector_53876-61377.jpg?w=740&t=st=1664898346~exp=1664898946~hmac=b07c3243ba93a50f6373782402687c1f200a39434da7778612130435c1e6955b'
    },
    {
      id: 3,
      description: 'Free consultation with our trusted doctors and get the best recomendations',
      title: 'Consultation',
      image:
        'https://img.freepik.com/free-vector/mental-health-awareness-concept_23-2148523714.jpg?w=740&t=st=1664898350~exp=1664898950~hmac=ead11e601262991fde5a17f2cf762e097930c81c149575c3dd8963b57fbfe3dc'
    },
    {
      id: 4,
      description: 'Free consultation with our trusted doctors and get the best recomendations',
      title: 'Details info',
      image:
        'https://img.freepik.com/free-vector/fomo-fear-missing-out-concept_52683-45499.jpg?w=740&t=st=1664898388~exp=1664898988~hmac=f4ad113b6c2c6869b216bc34cb6a052b5bdda4a068fe1f3016857c6313ee376c'
    },
    {
      id: 5,
      description:
        'You can get 24/7 urgent care for yourself or your children and your lovely family',
      title: 'Emergency care',
      image:
        'https://img.freepik.com/free-vector/fear-missing-out-concept_23-2148650949.jpg?w=740&t=st=1664898392~exp=1664898992~hmac=7b4c36da47824d025e93c63fc8f9879737a2576ee0d9b012d2c11e8861982174'
    },
    {
      id: 6,
      description: 'Track and save your medical history and health data',
      title: 'Tracking',
      image:
        'https://img.freepik.com/free-vector/fomo-vs-jomo_23-2148653141.jpg?w=1060&t=st=1664898425~exp=1664899025~hmac=47a715606811e4b9a93a655f3fb9396f0c3c746b59861ac882a80222244b4ae3'
    }
  ];

  return (
    <section id="team" className="pb-10 lg:pb-20 bg-[#D9EBFA]">
      <div className="w-full">
        <div className="flex flex-wrap mb-5 pt-10">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[620px] text-center">
              <div className="w-full py-2 text-secondary text-center font-medium text-4xl">
                Our Services
              </div>
              <div className=" w-32 h-1 bg-primary rounded-sm mx-auto mt-4"></div>
              <p className="text-lg mt-5 text-secondary font-medium opacity-50 leading-relaxed">
                There are many variations of passages of Lorem Ipsum available but the majority have
                suffered alteration in some form.
              </p>
            </div>
          </div>
        </div>
        <div className="grid xl:grid-cols-3 md:grid-cols-2 md:px-10 sm:px-10  gap-x-5 gap-y-5 max-w-5xl mx-auto">
          {services.map((service) => {
            return (
              <div
                key={service.id}
                className="shadow-[10px_40px_50px_rgba(229,233,246,0.5)] border border-secondary/[.1] bg-white hover:cursor-pointer hover:bg-slate-100 hover:border-white px-6 py-6 w-full rounded-[32px]">
                <div>
                  <div>
                    <img src={service.image} className=" w-full h-52 mb-5 rounded-[32px]"></img>
                  </div>
                  <div className=" text-xl font-bold text-secondary text-center w-full">
                    {service.title}
                  </div>
                  <div className="text-secondary text-base text-center w-full font-medium opacity-50">
                    {service.description}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
